.n-testimonial-card {
    /* max-width: 650px;
    min-width: 450px; */
    
    width: 600px;
    min-width: 600px;
    height: 200px;

    position: relative;
    box-sizing: border-box;
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
    /* padding: 2%; */
    /* margin: 1%; */
    /* padding-top: 2%; */
    /* padding-right: 1%; */
    padding-top: 15px;
    padding-right: 10px;
    padding-left: 15px;
    background-color: white;
    box-shadow: 5px 8px #D17E1C;

    /* background-color: blue; */
}

.n-testimonial-card-container {
    position: relative;
    z-index: 2;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
    /* background-color: brown; */
    box-sizing: border-box;
    column-gap: 4%;
}

.n-testimonial-card-left {
    /* width: 100px; */
    width: 180px;
    height: 180px;
    padding: 0px;
    margin: 0px;
    /* flex-grow: 0; */
    /* background-color: darkorange; */
    box-sizing: border-box;
    /* overflow: hidden; */
    display: flex;
    justify-content: center;
}

.n-testimonial-card-right {
    flex: 1;
    /* background-color: crimson; */
    box-sizing: border-box;
    padding: 1%;
    height: 195px;
    overflow: hidden;
    /* background: green; */
    padding: 0;
}

.n-testimonial-card-left-container {
    position: relative;
    /* width: 180px; */
    flex: 0.8;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.parallelogram {
    position: absolute;
    z-index: 1;
    bottom: -20px;
    left: 0px;
    right: 0px;
	height: 90px;
	transform: skewY(-2deg);
	background: rgb(255, 255, 255);
    border-bottom-left-radius: 20px;
    border-bottom-right-radius: 20px;
    box-shadow: 5px 10px #D17E1C;
}

.n-testimonial-content {
    text-align: left;
    font: normal normal normal 12px Poppins;
    letter-spacing: 0px;
    color: #2B2B2B;
    opacity: 1;
    margin-bottom: 10px;
    /* background-color: yellow; */
    /* width: 360px; */
    overflow: hidden;
    
    max-height: 128px;
    text-overflow: ellipsis;
}

.n-testimonial-writer {
    text-align: left;
    font: normal normal 600 16px Poppins;
    letter-spacing: 0px;
    color: #2B2B2B;
    opacity: 1;
    margin-bottom: 5px;
}

.n-testimonial-address {
    text-align: left;
    font: normal normal normal 13px Poppins;
    letter-spacing: 0px;
    color: #2B2B2B;
    opacity: 1;
}

.video-img {
    position: absolute;
    top: 40px;
    right: -10%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 50px;
}

.bees-and-bear-img-bottom {
    display: none;
}

@media  only screen and (max-width: 768px) {
    .n-testimonials {
        margin-top: 200px;
    }

    .n-testimonial-card {
        width: 600px;
        /* width: 100%; */
        min-width: unset;
        background-color: white;
        justify-content: center;
        align-items: center;
        border-bottom-right-radius: 60px;
        
    }

    .n-testimonial-cards {
        overflow-x:unset;

    }

    .test-slider-group {
        width: -webkit-fill-available;
    }
    .test-card {
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 5%;
    }
    .n-testimonial-card-container {
        display: flex;
        flex-direction: column;
        padding: 5%;
    }
    .n-testimonial-card-left-container {
        width: 80%;
        max-width: 100%;
        margin: 5%;
        padding: 5%;
    }
    .n-testimonial-card-left {
        justify-content: center;
    }
    .video-img {
        bottom: 0px;
        right: 0%;
        top:auto;
    }

    .parallelogram {
        background-color: white;
        border-bottom-right-radius: 40px;
    }

    .n-testimonial-card-right {
        width: fit-content;
        padding: 5%;
    }
    .n-testimonials-body-container {
        padding: 5%;
    }

    .bees-and-bear-img {
        display: none;
    }
    .bees-and-bear-img-bottom {
        display: flex;
        max-width: 190px;
        place-self: flex-end;
        margin-top: -32%;
        z-index: 1;
    }
}