.header {
  width: 100%;
  height: 88px;
  position: fixed;
  z-index: 999;
  background-color: #FFFFFF;
  box-shadow: 0px 3px 6px #0000000d;
  opacity: 1;
}

.mobile-menu-wrapper {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
}

.header-container {
  box-sizing: border-box;
  padding: 10px;
  margin: 0 auto;
  width: 80%;
  height: 100%;
  /* background-color: yellow; */
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  column-gap: 20px;
}

#mobile-menu {
  display: none;
  height: 34px;
  width: 34px;
  position: absolute;
  top: 8px;
  right: 20px;
}

#mobile-close {
  display: none;
  height: 34px;
  width: 34px;
  position: absolute;
  top: 8px;
  right: 20px;
}

#mobile-menu-flexgenie {
  display: none;
  height: 34px;
  width: 34px;
  position: absolute;
  top: 16px;
  right: 20px;
}

#mobile-close-flexgenie {
  display: none;
  height: 34px;
  width: 34px;
  position: absolute;
  top: 16px;
  right: 20px;
}

.header-left {
  /* background-color: green; */
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  column-gap: 5px;
}

.logo {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 30px;
  box-sizing: border-box;
}

.logo-flexgenie {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 75px;
  height: 75px;
  box-sizing: border-box;
}

.header-right-1 {
  width: 30%;
  min-width: 150px;
  max-width: 300px;
  /* background-color: hotpink; */
}

.flexagn {
  text-align: left;
  font: normal normal 600 21px/31px Poppins;
  letter-spacing: 0.9px;
  color: #185dd2;
  opacity: 1;
}

.header-right-1-container {
  /* background-color: lawngreen; */
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.menu-item {
  text-align: right;
  font: normal normal normal 15px/50px Poppins;
  letter-spacing: 0.65px;
  color: #2b2b2b;
}



.menu-item-flexgenie {
  text-align: right;
  font: normal normal normal 15px/50px Poppins;
  letter-spacing: 0.65px;
  color: #2b2b2b;
}

.menu-item:hover {
  color: #185dd2;
  font-weight: 600;
  cursor: pointer;
  text-decoration: none;
}

.menu-item-flexgenie:hover {
  color: #C23FA1;
  font-weight: 600;
  cursor: pointer;
  text-decoration: none;
}

@media only screen and (max-width: 768px) {

  .header-right-1-container {
    display: none;
  }

  .header-right-2 {
    display: flex;
    width: 100%;
  }

  #mobile-menu {
    display: block;
  }

  #mobile-close {
    display: block;
  }

  #mobile-menu-flexgenie {
    display: block;
  }

  #mobile-close-flexgenie {
    display: block;
  }

  .logo-flexgenie {
    width: 50px;
    height: 50px;
  }

  .header {
    height: fit-content;
  }

  .header-right-2-mobile {
    display: flex !important;
  }

  .header-right-2-container {
    display: none !important;
  }

  .mobileMenuIcon {
    display: block !important;
  }

  .header-container {
    width: 95%;
  }
}

.mobileMenuIcon {
  display: none;
}



.header-right-2-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  column-gap: 20px;
  box-sizing: border-box;
  overflow: hidden;
}

.header-right-2-mobile {
  display: none;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  column-gap: 20px;
  box-sizing: border-box;
  overflow: hidden;
  width: 100%;
}

.login-btn {
  /* width: 120px; */
  height: 40px;
  border: none;
  cursor: pointer;
  border-radius: 90px;
  border-width: 0px;
  opacity: 1;
  border-color: unset;
  /* padding: 3.5%; */
  font: 11px Poppins;
  letter-spacing: 0px;
  opacity: 1;

  background-color: transparent;
  color: #185dd2;
  font-weight: bold;
}

.login-btn:hover {
  color: white;
  background-color: #185dd2;
  font-weight: unset;
}

.overlay {
  height: 100%;
  width: 0;
  position: fixed;
  z-index: 1;
  top: 0;
  left: 0;
  background-color: rgb(0, 0, 0);
  background-color: rgba(0, 0, 0, 0.9);
  overflow-x: hidden;
  transition: 0.5s;
}

.overlay-content {
  position: relative;
  top: 25%;
  width: 100%;
  text-align: center;
  margin-top: 30px;
}

.overlay a {
  padding: 8px;
  text-decoration: none;
  font-size: 36px;
  color: #818181;
  display: block;
  transition: 0.3s;
}

.overlay a:hover,
.overlay a:focus {
  color: #f1f1f1;
}

.overlay .closebtn {
  position: absolute;
  top: 20px;
  right: 45px;
  font-size: 60px;
}