.subs-card {
  max-width: 210px;
  min-width: 120px;
  width: 90%;
  box-sizing: border-box;
  box-shadow: 1px 1px 30px -15px cadetblue;
  border: 2px;
  border-radius: 25px;
}

.subs-flexgenie {
  max-width: 210px;
  min-width: 120px;
  width: 90%;
  box-sizing: border-box;
  box-shadow: 1px 1px 30px -15px cadetblue;
  border: 2px;
  border-radius: 25px;
}



.subscription-img {
  width: 100%;
  max-width: 130px;
}

.subs-card-container {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;

  /* background: #F8AD61 0% 0% no-repeat padding-box; */
  /* box-shadow: 0px 0px 11px #F8AD61; */
  border-radius: 18px;
  opacity: 1;
  padding: 20px 20px;
  box-sizing: border-box;
  row-gap: 10px;
  background-color: rgb(245, 249, 255);
}

.subs-card-flexgenie {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  border-radius: 18px;
  opacity: 1;
  padding: 20px 20px;
  box-sizing: border-box;
  row-gap: 10px;
  background-color: white;
  height: 360px;
  min-width: 230px;
  max-width: 300px;
}

.subs-card-1-meta-tag {
  text-align: center;
  height: 20px;
  /* background-color: aqua; */
  font-size: 10px;
  font-weight: bold;
  color: white;

  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.subs-card-1-meta-tag-container {
  background-color: black;
  padding: 2%;
  border-radius: 3.5px;
}

.subs-card-2-header {
  height: 30px;
  /* background-color: #e97400; */
  text-align: center;
  font: normal normal medium 24px Poppins;
  letter-spacing: 0px;
  color: #2b2b2b;
  opacity: 1;
}

.subs-card-3-description {
  height: 50px;
  /* background-color: chartreuse; */
  text-align: center;
  font: normal normal medium 16px Poppins;
  letter-spacing: 0px;
  color: #6f6f6f;
  opacity: 1;
}

.subs-card-desc-flexgenie {
  text-align: center;
  font: normal normal 600 18px Poppins;
  letter-spacing: 0px;
  color: #6f6f6f;
  opacity: 1;
  margin-bottom: 20px;
}

.subs-card-4-img {
  height: 90px;
  /* background-color: darkblue; */
  box-sizing: border-box;
  margin: 0 auto;
  margin-top: 10px;
}

.subs-card-5-footer {
  text-align: center;
  height: 30px;
}

.subs-card-container:hover {
  background-color: yellow;
}

.subs-card-flexgenie:hover {
  background-color: #ffdfef;
}

@media only screen and (max-width: 768px) {
  .subs-card {
    min-width: 300px;
    min-height: 390px;
    background-color: white;
  }

  .subs-flexgenie {
    min-width: 240px;
    min-height: fit-content;
    background-color: white;
  }

  .subs-card-container {
    background-color: white;
    min-width: 300px;
    min-height: 390px;
  }

  .subs-card-flexgenie {
    background-color: white;
    min-width: 240px;
    height: 300px;
  }

  .subs-card-container:hover {
    background-color: #FFDC00;
  }

  .subscription-img {
    min-width: 148px;
    min-height: 90px;
  }

  .subs-card-2-header {
    font: normal normal 600 18px Poppins;

  }

  .subs-card-3-description {
    font: normal normal 600 16px Poppins;

  }

  .subs-card-desc-flexgenie {
    font: normal normal 600 16px Poppins;

  }


  .subs-card-1-meta-tag {
    font: normal normal 600 18px Poppins;
    margin: 12px 0px;

  }
}