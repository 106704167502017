.contact-us {
    margin: 0 auto;
    box-sizing: border-box;
    margin-top: 10px;
    margin-bottom: 50px;
    margin-left: 14%;
    margin-right: 14%;
    padding: 1% 1%;

    background-image: url("../assets/images/backgrounds/bg5.png");
    background-size: contain;
}

.contact-us-flexgenie {
    margin: 0 auto;
    box-sizing: border-box;
    margin-top: 10px;
    margin-bottom: 50px;
    margin-left: 14%;
    margin-right: 14%;
    padding: 1% 1%;
    background-color: white;
}

.contact-us-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    box-sizing: border-box;
    column-gap: 15%;
    /* background-color: yellowgreen; */
}

.contact-us-left {
    flex: 1;
    /* background-color: aqua; */
    box-sizing: border-box;
}

.contact-us-right {
    flex: 1;
    /* background-color: darkgoldenrod; */
    box-sizing: border-box;
}

.contact-us-left-container {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    box-sizing: border-box;
}

.contact-us-right-container {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    box-sizing: border-box;
}

.envelope-img {
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    max-width: 300px;
}

.contact-text-container {
    box-sizing: border-box;
    width: 100%;
    /* background-color: rgb(255, 166, 166); */
    padding: 4%;
}

.contact-text-1 {
    text-align: left;
    font: normal normal 600 42px Poppins;
    letter-spacing: 0px;
    color: #2B2B2B;
    opacity: 1;
    margin-bottom: 14px;
}

.contact-text-2 {
    text-align: left;
    font: normal normal 600 24px Poppins;
    letter-spacing: 0px;
    color: #2B2B2B;
    opacity: 1;
    margin-bottom: 8px;
}

.contact-text-3 {
    text-align: left;
    font: normal normal 500 20px Poppins;
    letter-spacing: 0px;
    color: #2B2B2B;
    opacity: 1;
    margin-bottom: 6px;
}

.contact-form-container {
    width: fit-content;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    background-color: #f5f9ff;
    padding: 7% 10%;
    border-radius: 25px;
}

.contact-form-header {
    text-align: left;
    font: normal normal 600 26px Poppins;
    letter-spacing: 0px;
    color: #2B2B2B;
    opacity: 1;
    margin-bottom: 20px;
}

.contact-form-input-body {
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    column-gap: 10px;
    justify-content: flex-start;
    align-items: flex-start;
    /* background-color: chartreuse; */
    width: 100%;
    box-sizing: border-box;
    margin-bottom: 15px;
}

.contact-form-label {
    text-align: left;
    font: normal normal 600 13px Poppins;
    letter-spacing: 0px;
    color: #2B2B2B;
    opacity: 1;
    margin-bottom: 7px;
}


.contact-form-input {
    width: 350px;
    max-width: 500px;
    border: 1px solid #CFD9EA;
    border-radius: 8px;
    opacity: 1;
    height: 27px;
    text-align: left;
    font: normal normal normal 14px Poppins;
    letter-spacing: 0px;
    color: #000000;
    outline: none;
    padding: 4px 12px;
    display: flex;
    align-items: center;
}

.contact-form-textarea {
    width: 350px;
    min-height: 100px;
    max-width: 500px;
    border: 1px solid #CFD9EA;
    border-radius: 8px;
    opacity: 1;
    text-align: left;
    font: normal normal normal 14px Poppins;
    letter-spacing: 0px;
    color: #000000;
    resize: none;
    outline: none;
    padding: 4px 12px;
    display: flex;
    align-items: center;
}

.submit-btn-container {
    width: 100%;
    box-sizing: border-box;
    margin-top: 5%;
}

.number-nine-container {
    position: absolute;
    top: 35%;
    right: 5%;
    width: 90%;
    /* height: 90%; */
    max-width: 50px;
    min-width: 30px;
    /* background-color: rgb(92, 92, 15); */
    display: flex;
    justify-content: center;
    align-items: center;
}

.number-ten-container {
    position: absolute;
    box-sizing: border-box;
    bottom: 0px;
    right: -30%;
    width: 90%;
    /* height: 90%; */
    max-width: 100px;
    min-width: 60px;
    /* background-color: yellow; */
    display: flex;
    justify-content: center;
    align-items: center;
}

input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    margin: 0;
}

.submit-btn {
    cursor: pointer;
    color: #FFFFFF;
    border-radius: 90px;
    border-width: 0px;
    opacity: 1;
    border-color: unset;
    padding: 3.5% 6%;
    width: 100%;
    font: normal normal 600 13px Poppins;
    letter-spacing: 0px;
    background-color: #185DD2;
    text-transform: uppercase;
    opacity: 1;
    margin-top: 30px;
}

#envelope {
    width: 100%;
}

#nine {
    width: 100%;
}

#ten {
    width: 100%;
}

/* responsiveness */

@media only screen and (max-width: 768px) {
    .contact-us-container {
        flex-direction: column;
        row-gap: 30px;
        align-items: flex-start;
    }

    .contact-form-header {
        font: normal normal 600 16px Poppins;
        margin-bottom: 20px;

    }

    .contact-text-1 {
        font: normal normal 600 28px Poppins;
        text-align: left;
        margin-bottom: 20px;
    }

    .contact-text-2 {
        font: normal normal 600 20px Poppins;
        margin-top: 10px;
    }

    .contact-text-3 {
        font: normal normal 300 16px Poppins;
    }

    .contact-us {
        margin: 0px 8px;
        margin-bottom: 30px;
        margin-top: 10px;
    }

    .contact-us-flexgenie {
        margin: 0px 8px;
        margin-bottom: 30px;
        margin-top: 10px;
    }

    .contact-form-input {
        width: 100%;
        margin-bottom: 20px;
        height: 50px;
    }

    .contact-form-textarea {
        width: 100%;
        margin-bottom: 20px;
        height: 120px;
    }

    .contact-form-label {
        font: normal normal 500 12px Poppins;

    }

    .submit-btn-container {
        text-align: center;
    }

    .submit-btn {
        cursor: pointer;
        color: #FFFFFF;
        border-radius: 90px;
        border-width: 0px;
        opacity: 1;
        border-color: unset;
        padding: 8px 12px;
        width: 70% !important;
        height: 50px;
        font: normal normal 400 16px Poppins;
        letter-spacing: 0px;
        background-color: #185DD2;
        text-transform: uppercase;
        opacity: 1;
    }

    .envelope-img {
        justify-content: flex-start;
    }

    #envelope {
        width: 65%;
    }

    #nine {
        width: 50%;
    }

    #ten {
        width: 50%;
    }

    .contact-form-container {
        padding: 12px;
        width: 93vw;
    }
}