.fayf-card {
  /* max-width: 450px; */
  max-width: 750px;
  min-width: 300px;
  width: 100%;
}

.fayf-card-flexgenie {
  max-width: 750px;
  min-width: 300px;
  width: 100%;
}

.fayf-card-container {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;

  /* background: #FF4F67 0% 0% no-repeat padding-box; */
  /* box-shadow: 0px 0px 11px #FF4A62; */
  color: black;
  border-radius: 18px;
  opacity: 1;
  padding: 21px;
  row-gap: 2px;
}

.card-container-flexgenie {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;

  /* background: #FF4F67 0% 0% no-repeat padding-box; */
  /* box-shadow: 0px 0px 11px #FF4A62; */
  color: black;
  border-radius: 18px;
  opacity: 1;
  padding: 21px;
  row-gap: 2px;
}

.fayf-card-header {
  text-align: left;
  font: normal normal 600 18px Poppins;
  letter-spacing: 0px;
  /* color: #FFFFFF; */
  opacity: 1;
}

.fayf-card-description {
  text-align: left;
  font: normal normal normal 14px Poppins;
  letter-spacing: 0px;
  /* color: #FFFFFF; */
  opacity: 0.81;
}

.fayf-card-container:hover {
  background: #ffb100 0% 0% no-repeat padding-box;
  box-shadow: 0px 0px 11px #f8ad61;
  color: #2b2b2b;
}

.fayf-card-container-flexgenie {
  background: #C23FA1 0% 0% no-repeat padding-box;
  color: white;
  min-height: 100px;
  justify-content: center;
  cursor: pointer;

}

.fayf-card-container-flexgenie:hover {
  background: #C23FA1 0% 0% no-repeat padding-box;
  color: white;

}

@media only screen and (max-width: 768px) {
  .fayf-card-container {
    background: #FFB100 0% 0% no-repeat padding-box;
    box-shadow: 0px 0px 11px #f6fcfd;
    color: black;
    /* margin-left: 6%; */
    max-width: 330px;
    min-height: 166px;
    overflow: hidden !important;
  }

  .card-container-flexgenie {
    background: #C23FA1 0% 0% no-repeat padding-box;
    box-shadow: 0px 0px 11px #f6fcfd;
    color: white;
    /* margin-left: 6%; */
    max-width: 330px;
    min-height: 142px;
    overflow: hidden !important;
  }

  .fayf-card-container-flexgenie {
    background: #B5D1FF 0% 0% no-repeat padding-box;
    min-height: 70px;

  }

  .fayf-card-header {
    font: normal normal 600 16px Poppins;
  }

  .fayf-card-description {
    font: normal normal normal 12px Poppins;
  }

  .fayf-card-container:hover {
    background: #FFB100 0% 0% no-repeat padding-box;
    color: black;
  }

  .carousel-container-fayf {
    width: 350px !important;
    overflow: visible !important;
    transition: all 1s ease !important;
  }

  .fayf-item-carousel {
    max-width: 350px !important;
    min-width: 350px !important;
  }

  .custom-dot-list-style-flexgenie {
    top: 160px !important;
  }

  .custom-dot-list-style-fayf {
    /* background-color: #b7dbf8 !important; */
    bottom: 0px !important;
  }


  .react-multi-carousel-dot button {
    background-color: #b7dbf8;
    border-radius: 0px;
  }

  .react-multi-carousel-dot button:active {
    background-color: black;

  }
}