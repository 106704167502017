.about-us {
  box-sizing: border-box;
  /* background-color: yellow; */
  margin-top: 10px;
  margin-bottom: 10px;
  margin-left: 12%;
  margin-right: 12%;
}

.about-us-container {
  width: 100%;
  display: flex;
  /* flex-direction: column; */
  justify-content: space-between;
  align-items: flex-start;
  /* background-color: coral; */
  box-sizing: border-box;
  column-gap: 6%;
  padding-top: 5px;
  padding-bottom: 5px;
  row-gap: 20px;
}

.about-us-texts {
  /* width: 60% this; */
  /* min-width: 510px; */
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  /* background-color: coral; */
  box-sizing: border-box;
  column-gap: 6%;
  padding-top: 5px;
  padding-bottom: 5px;
  row-gap: 20px;
}

.about-us-title {
  text-align: left;
  font: normal normal 700 42px Poppins;
  letter-spacing: 1.6px;
  color: #2b2b2b;
  opacity: 1;
  width: 100%;
}

.about-us-header {
  text-align: left;
  font: normal normal 600 24px Poppins;
  letter-spacing: 0px;
  color: #2b2b2b;
  opacity: 1;
  width: 100%;
  margin-bottom: 20px;
}

.about-us-description {
  text-align: justify;
  font: normal normal normal 15px Poppins;
  letter-spacing: 0px;
  color: #2b2b2b;
  opacity: 1;
  width: 100%;
}


.about-us-cards img{
  width: -webkit-fill-available;
}
.about-container{
  align-self: end;
}
.about-container{
  width: 40%;
}

@media only screen and (max-width: 768px) {
  .about-us-texts {
    width: 100%;
    /* min-width: unset; */
  }
  .about-us{
    margin: 6%;
  }
  .about-us-container{
    display: block;
    width: 100%;
  }
 .about-container{
   /* width: fit-content; */
   width: 80%;
 }
 .about-container{
   width: 75%;
 }

  .about-us-title {
    width: 100%;
    text-align: center;
    font: normal normal 600 28px/42px Poppins;

  }

  .about-us-header {
    text-align: center;
    font: normal normal 400 22px/33px Poppins;
  }

  .about-us-description {
    text-align: center;
  }
}
