.fayf {
  box-sizing: border-box;
  /* background-color: yellow; */
  /* margin-top: 40px; */
  margin-bottom: 10px;
  /* margin-left: 10%;
    margin-right: 14%; */

  margin-top: 20px;
  /* margin-left: 9%; */
  /* margin-right: 9%; */
  padding-left: 11%;
  padding-right: 9%;
  padding-top: 20px;
  padding-bottom: 10px;
  /* border-radius: 25px; */
  background-color: rgb(245, 249, 255);
}

.fayf-flexgenie {
  box-sizing: border-box;
  margin-bottom: 10px;

  margin-top: 20px;
  padding-left: 11%;
  padding-right: 9%;
  padding-top: 20px;
  padding-bottom: 10px;
  background-color: white;

}

.fayf-container {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  /* background-color: coral; */
  box-sizing: border-box;
  /* column-gap: 6%; this */
  column-gap: 1%;
  padding-top: 5px;
  padding-bottom: 5px;
}

.fayf-left {
  /* flex: 6; this */
  flex: 1;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  flex-wrap: wrap;
  /* background-color: palevioletred; */
}

.fayf-right {
  /* flex: 4; this */
  flex: 1;
}

.fayf-right-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  column-gap: 30px;
  align-items: flex-end;
  box-sizing: border-box;
  /* background-color: yellowgreen; */
}

.fayf-star {
  display: none;
  box-sizing: border-box;
  width: 90%;
  max-width: 95px;
  min-width: 60px;
}

.gif-container {
  /* background-color: yellow; */
  max-width: 300px;
  width: 70%;
}

.fayf-header {
  width: 100%;
  text-align: center;
  font: normal normal bold 42px Poppins;
  letter-spacing: 1.6px;
  color: #2b2b2b;
  opacity: 1;
  margin-bottom: 37px;
}

.fayf-sub-header {
  width: 100%;
  text-align: center;
  font: normal normal 500 24px Poppins;
  letter-spacing: 1.6px;
  color: #2b2b2b;
  opacity: 1;
  margin-top: -37px;
  margin-bottom: 37px;
}


.fayf-card-list {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  /* align-items: flex-end;  */
  flex-wrap: wrap;
  row-gap: 30px;
}

.fayf-carousel-body {
  display: none;
}

@media only screen and (max-width: 768px) {
  .fayf-carousel-body {
    display: unset;
  }

  .fayf-sub-header {
    font-size: 20px;
  }

  .fayf {
    margin-left: 1%;
    margin-right: 1%;
    margin-top: 0px;
    margin-bottom: 0px;
    padding-bottom: 0px;
  }

  .fayf-flexgenie {
    margin: 0px;
    padding-bottom: 0px;
    height: 820px;
  }

  .fayf-container {
    flex-direction: column-reverse;
    row-gap: 15px;
  }

  .fayf-left {
    max-width: 100%;
    overflow-x: auto;
  }

  .gif-container {
    margin-bottom: 30px;
    max-width: 250px;
  }

  .fayf-card-list {
    width: 100%;
    flex-direction: row;
    justify-content: flex-start;
    flex-wrap: unset;
    column-gap: 13px;
  }

  .fayf-card-list {
    display: none;
  }

  .fayf-star {
    width: 60% !important;
    max-width: 75px !important;
    min-width: 10px !important;
  }

  .fayf-header {
    font: normal normal bold 28px Poppins;
  }
}