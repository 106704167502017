.olf {
  margin-top: 50px;
  margin-bottom: 10px;
  margin-left: 6%;
  margin-right: 6%;
  box-sizing: border-box;
  background-image: url('../assets/images/backgrounds/bg1.png');
  background-size: contain;
}

.olf-container {
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: stretch;
  align-items: stretch;
  /* background-color: yellow; */
  box-sizing: border-box;
}

.olf-heading {
  width: 100%;
  /* height: 60px; */
  text-align: center;
  font: normal normal 600 42px Poppins;
  letter-spacing: 0px;
  color: #2b2b2b;
  opacity: 1;
  padding-left: 10%;
  padding-right: 10%;
  box-sizing: border-box;

  /* background-color: aqua; */
}

.star {
  position: absolute;
  top: -40px;
  left: 0px;
  box-sizing: border-box;
  width: 90%;
  max-width: 95px;
  min-width: 60px;
}

.olf-body {
  width: 100%;
  box-sizing: border-box;
  margin-top: 40px;
}

.olf-body-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  /* background-color: green; */
  /* column-gap: 6%; */
  column-gap: 2%;
}

.olf-left {
  /* flex: 6; */
  flex: 1;
}

.olf-right {
  flex: 1;
}

.olf-left-container {
  /* width: 100%; */
  height: 100%;
  /* background-color: yellowgreen; */
  display: flex;
  justify-content: flex-end;
}

.olf-right-container {
  /* width: 100%; */
  height: 100%;
  /* background-color: pink; */
  display: flex;
  flex-direction: column;
  row-gap: 20px;
  justify-content: center;
  align-items: flex-start;
  padding-left: 1%;
  flex-wrap: wrap;
}

.carousel-inner {
  display: none;
}

/* responsiveness */

@media only screen and (max-width: 768px) {
  div#myCarousel.carousel.slide{
    height: 540px !important;
  }
  .carousel-inner {
    display: unset;
  }

  .olf-right-container {
    display: none;
  }
.olf-left{
  /* display: none; */
}
  .olf {
    margin-top: 50px;
    margin-left: 0px;
    margin-right: 0px;
    /* margin-bottom: 50px; */
    height: 98vh;
  }
  

  .olf-heading {
    position: absolute;
    font: normal normal 600 28px Poppins;
    top: -12;
  }

  .star {
    width: 60% !important;
    max-width: 75px !important;
    min-width: 10px !important;
  }

  .olf-body-container {
    flex-direction: column;
    /* row-gap: 30px; */
  }

  .olf-right {
    display: none;
    max-width: 100%;
    overflow-x: auto;
  }

  .olf-right-container {
    width: 100%;
    flex-direction: row;
    justify-content: flex-start;
    flex-wrap: unset;
    column-gap: 14px;
  }
  /* .olf-slider-container{
    display: flex;
    height: fit-content;
    width: 100%;
  } */
  /* .carousel-indicators-olf{
    display: flex !important;
  } */
  .gif{
    max-width: 326px;
  }
  .olf-body-container{
    padding-bottom: 0px;
  }
}
@media only screen and (min-width: 380px) and (max-width:420px){
  .olf{
    height: 80vh;
  }
}


/* carousel css */
.olf-body-container{
  padding-bottom: 30px;
}
.carousel-inner{
  position: relative;
}
.gif {
  width: 100%;
  max-width: 550px;
}




.carousel-indicators-1 {
  display: flex !important;
  background-color: none !important;
  background: none !important;
  bottom: -37px;
}

.carousel-indicators-1 li {
  border: none !important;
  background-color: lightblue;
  margin-right: 10px !important;
}

.carousel-indicators-1:active {

}

div#myCarousel.carousel.slide {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  /* background-color: red; */
  margin: 0 auto;
}

