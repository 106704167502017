.osm {
  box-sizing: border-box;
  /* margin-top: 40px; this */
  margin-top: 30px;
  /* margin-left: 7%;
  margin-right: 7%; */
  background-image: url('../assets/images/backgrounds/bg2.png');
  background-size: contain;
}

.osm-container {
  display: flex;
  flex-direction: column;
  justify-content: stretch;
  align-items: center;
  /* background-color: yellow; */
  box-sizing: border-box;
}

.osm-container-flexgenie {
  background-color: #FAF2FA;
}

.osm-header {
  display: none;
  /* background-color: rgb(255, 149, 149); */
  width: 100%;
}

.osm-body {
  background-color: rgb(245, 249, 255);
  width: 100%;
  box-sizing: border-box;
  padding-top: 2%;
}


.osm-header-container {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  align-items: flex-end;
  justify-content: stretch;
  /* background-color: red; */
  margin-bottom: 90px;
}

/* frog img */
.osm-header-left {
  flex: 1;
  /* max-width: 190px; */
  max-width: 150px;
  min-width: 100px;
}

.osm-header-right {
  position: absolute;
  left: 0px;
  right: 0px;
  top: 40px;
  padding: 0% 10%;
  /* flex: 6; */
  /* background-color: rgb(245, 229, 137); */
}

.osm-header-right-container {
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  justify-content: center;
  align-items: flex-end;
  row-gap: 15px;
}

.osm-body-container {
  box-sizing: border-box;
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  /* justify-content: space-around; */
  justify-content: center;
  align-items: flex-start;
  /* background-color: rgb(0, 102, 255); */
  padding: 2%;
  row-gap: 20px;
  /* column-gap: 20px; this */
  column-gap: 5%;
}

.osm-header {
  text-align: center;
  font: normal normal 600 42px Poppins;
  letter-spacing: 0px;
  color: #2b2b2b;
  opacity: 1;
  /* background-color: #185DD2; */
}

.osm-sub-header {
  text-align: center;
  font: normal normal normal 17px Poppins;
  letter-spacing: 0px;
  color: #2b2b2b;
  opacity: 1;
  /* padding: 0% 20%; */
  padding: 0% 15%;
}


.osm-footer {
  width: 100%;
  background-color: rgb(245, 249, 255);
}

.osm-footer-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  padding: 20px;
}

.osm-explore-btn {
  cursor: pointer;
  background: #185DD2 0% 0% no-repeat padding-box;
  border-radius: 90px;
  border: none;
  padding: 1.5% 4.5%;

  text-align: center;
  font: normal normal 600 14px Poppins;
  letter-spacing: 0px;
  color: #ffffff;
  text-transform: uppercase;
  opacity: 1;
}

a {
  -webkit-appearance: button;
  -moz-appearance: button;
  appearance: button;

  text-decoration: none;
  color: initial;
}

.osm-body-header {
  font: normal normal 600 42px Poppins;
  font-size: 42px;
  color: #2B2B2B;
  font-weight: bold;
  text-align: center;
  margin-top: 20px;
}

.osm-body-subheader {
  font: normal normal 500 20px Poppins;
  color: #2B2B2B;
  text-align: center;
  margin-bottom: 20px;

}

.osm-subheader-flexgenie {
  font: normal normal 500 24px Poppins;
  color: #2B2B2B;
  text-align: center;
  margin-bottom: 20px;
}

/* responsiveness */

@media only screen and (max-width: 768px) {
  .osm-header-container {
    flex-direction: column;
    align-items: unset;
    margin-bottom: unset;
  }

  .osm-footer {
    width: 222px;
  }

  .osm-body-subheader {
    font: normal normal normal 18px/27px Poppins;
  }

  .osm-body-header {
    font: normal normal 600 28px/42px Poppins;
  }

  .osm-header-left {
    width: 40%;
    position: relative;
  }

  .osm-sub-header {
    padding-left: 0;
    padding-right: 0;
  }

  .osm-header-right {
    position: unset;
    padding: unset;
  }

  .osm-explore-btn {
    width: 90%;
    padding: 8px 12px;
    font-size: 18px;
    font-weight: 600;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

/* tab view */
@media only screen and (max-width: 950px) {
  .osm-header-right {
    position: unset;
    padding: unset;
  }


  .osm-header-container {
    margin-bottom: unset;
  }
}