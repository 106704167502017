.app-footer {
    width: 100%;
    box-sizing: border-box;
    background: #3F4857 0% 0% no-repeat padding-box;
}

.app-footer-container {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    justify-content: stretch;
    align-items: center;
    box-sizing: border-box;
}

.app-footer-top {
    flex: 1;
    box-sizing: border-box;
    padding: 56px 5% 76px 5%;
    width: 100%;
}

.top-c1 {
    min-width: 150px;
    flex: 2;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
}

.top-c2 {
    min-width: 150px;
}

.top-c3 {
    min-width: 150px;
}

.top-c4 {
    min-width: 150px;
    max-width: 200px;
}

.top-c5 {
    min-width: 150px;
}

.app-footer-top-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
    box-sizing: border-box;
    column-gap: 3%;
    row-gap: 15px;
    flex-wrap: wrap;
    padding-left: 90px;
    padding-right: 29px;
}

.app-footer-flexagn {
    text-align: left;
    font: normal normal 600 22px Poppins;
    letter-spacing: 1.03px;
    color: #A5A5A5;
    opacity: 1;
    margin-bottom: 10px;
}

.c1-description {
    text-align: left;
    font: normal normal normal 13px Poppins;
    letter-spacing: 0.52px;
    color: #B5B5B5;
    opacity: 1;
    margin-bottom: 18px;
}

.c1-copyright {
    text-align: left;
    font: normal normal normal 13px Poppins;
    letter-spacing: 0.52px;
    color: #FFFFFF;
    opacity: 1;
}

.topic-container {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-items: flex-start;
    box-sizing: border-box;
    /* background-color: goldenrod; */
    row-gap: 6px;
}

.topic-header {
    text-align: left;
    font: normal normal 600 13px Poppins;
    letter-spacing: 0.56px;
    color: #FFFFFF;
    opacity: 1;
}

.topic-item {
    list-style-type: none;
    text-align: left;
    font: normal normal normal 13px Poppins;
    letter-spacing: 0.52px;
    color: #B5B5B5;
    opacity: 1;
}

ul {
    padding: unset;
    margin: unset;
}

li {
    margin-bottom: 6px;
}

.social-media-item {
    list-style-type: none;
    text-align: left;
    font: normal normal normal 13px Poppins;
    letter-spacing: 0.52px;
    color: #B5B5B5;
    opacity: 1;
    margin-bottom: 8px;
}

.social-media {
    /* width: 100%; */
    box-sizing: border-box;
}

.social-media-container {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    flex-wrap: wrap;
    /* background-color: darkviolet; */
    box-sizing: border-box;
    column-gap: 8px;
}

.social-media-img {
    width: 20px;
    height: 20px;
}

.social-media-text {
    text-align: left;
    font: normal normal normal 12px Poppins;
    letter-spacing: 0.52px;
    color: #B5B5B5;
    opacity: 1;
}

.app-footer-bottom {
    width: 100%;
    /* padding: 20px 5% 20px 5%; */
    padding: 11px 9% 13px 11%;
    box-sizing: border-box;
    background: #303741 0% 0% no-repeat padding-box;
}

.app-footer-bottom-container {
    /* background-color: aqua; */
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    box-sizing: border-box;
}

.app-footer-bottom-left {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    box-sizing: border-box;
    /* background-color: darkkhaki; */
    row-gap: 13px;
}

.country-support {
    text-align: left;
    font: normal normal normal 12px Poppins;
    letter-spacing: 0px;
    color: #FFFFFF;
    opacity: 1;
}

.countries {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    flex-wrap: wrap;
    box-sizing: border-box;
    /* background-color: magenta; */
    column-gap: 5%;
    row-gap: 10px;
}

.country {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    /* background-color: darkgoldenrod; */
    box-sizing: border-box;
    column-gap: 6px;
}

.flag-img {
    display: flex;
    justify-content: center;
    align-items: center;
}

.country-name {
    text-align: left;
    font: normal normal normal 13px Poppins;
    letter-spacing: 0px;
    color: #FFFFFF;
    opacity: 1;
}




.app-footer-bottom-right {
    flex: 1;
    /* background-color: red; */
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    column-gap: 15%;
    row-gap: 10px;
    box-sizing: border-box;
}

.footer-policy {
    text-align: right;
    flex-wrap: nowrap;
    text-align: left;
    font: normal normal normal 13px Poppins;
    letter-spacing: 0px;
    color: #FFFFFF;
    opacity: 1;
}
.footer-policy a{
    text-align: right;
    flex-wrap: nowrap;
    text-align: left;
    font: normal normal normal 13px Poppins;
    letter-spacing: 0px;
    color: #FFFFFF;
    opacity: 1;
}
.topic-item a{
    text-decoration: none;
    font: normal normal normal 13px Poppins;
    letter-spacing: 0.52px;
    color: #B5B5B5;
}


@media only screen and (max-width: 768px) {
.app-footer-top-container{
    padding-left: 0px;
    padding-right: 0px;
}
.app-footer-bottom-container{
    display: flex;
    flex-direction: column;
    width: 100%;
}
.app-footer-bottom-left{
    align-items: center;
    margin-bottom: 12px;
}
.country-support{
    font-size: 16px;
}
.countries{
    flex-wrap: nowrap;
    justify-content: center;
}
.flag-img{
    margin-right: 4px;
}
.country{
    margin: 10px;
}
.app-footer-bottom-right {
    flex: 1;
    /* background-color: red; */
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    box-sizing: border-box;
    width: 100%;
}
}