.olf-card {
    max-width: 350px;
    min-width: 240px;
    width: 90%;
}

.olf-card-container {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    color: black;
    /* background: #F8AD61 0% 0% no-repeat padding-box; */
    background: transparent;
    border-radius: 18px;
    opacity: 1;
    padding: 20px 20px;
    row-gap: 5px;
}

.olf-card-header {
    text-align: left;
    width: 100%;
    font: normal normal 600 19px Poppins;
    letter-spacing: 0px;
    /* color: black; */
    opacity: 1;
}

.olf-card-description {
    text-align: left;
    width: 100%;
    font: normal normal normal 14px Poppins;
    letter-spacing: 0px;
    /* color: black; */
    opacity: 0.81;
}

.olf-card-container:hover {
    background: #F8AD61 0% 0% no-repeat padding-box;
    color: white;
}

/* .olf-card-header:hover {
    color: white;
}

.olf-card-description:hover {
    color: white;
} */

/* responsiveness */
.olf-carousel-body {
    display: none;
}

@media only screen and (max-width: 768px) {
    .olf-card-container {
        background: #FFB100 0% 0% no-repeat padding-box;
        color: black;
        /* border: 2px solid #F8AD61; */
        margin-left: 10%;
        min-width: 250px;
        min-height: 166px;
        overflow: hidden;
        /* margin-right: 10%; */
        /* width: 100%; */
        -webkit-box-shadow: 2px 2px 5px -1px rgba(0, 0, 0, 0.75);
        -moz-box-shadow: 2px 2px 5px -1px rgba(0, 0, 0, 0.75);
        box-shadow: 2px 2px 5px -1px rgba(0, 0, 0, 0.75);
        -webkit-box-shadow: inset 0px 0px 4px -1px rgba(0, 0, 0, 0.75);
        -moz-box-shadow: inset 0px 0px 4px -1px rgba(0, 0, 0, 0.75);
        box-shadow: inset 0px 0px 4px -1px rgba(0, 0, 0, 0.75);
    }

    .olf-card-header {
        font: normal normal 600 18px Poppins;
    }

    .olf-card-description {
        font: normal normal normal 16px Poppins;

    }

    .olf-card-container:hover {
        background: #FFB100 0% 0% no-repeat padding-box;
        color: black;
    }

    /* .olf-card-container{
        background-color: #FFB100;
        color: black;
    } */
    .olf-carousel-body {
        display: unset;
    }

    .carousel-container-olf {
        width: 350px !important;
        overflow: visible !important;
        transition: all 1s ease !important;
    }

    .olf-item-carousel {
        max-width: 350px !important;
        min-width: 350px !important;
    }

    .react-multi-carousel-track {
        height: 218px !important;
    }

    .react-multi-carousel-item {
        height: fit-content !important;
    }

    .custom-dot-list-style-olf {
        /* background-color: #b7dbf8 !important; */
        display: flex;
        align-items: center;
        /* justify-content: space-evenly; */
        width: 100%;
        bottom: 0px !important;
    }

    .activeolf {
        width: 15px;
        height: 15px;
        background-color: #FFB100;
        border: 1px solid #FFB100;
        border-radius: 15px;
        object-fit: contain;
        margin-right: 10px;
        transition: all 0.5s ease;

    }

    .active-flexgenie {
        width: 15px;
        height: 15px;
        background-color: #C23FA1;
        border: 1px solid #C23FA1;
        border-radius: 15px;
        object-fit: contain;
        margin-right: 10px;
        transition: all 0.5s ease;
    }

    .inactiveolf {
        width: 15px;
        height: 15px;
        border-radius: 15px;
        background-color: white;
        border: 1px solid #FFB100;
        object-fit: contain;
        margin-right: 10px;
        transition: all 0.5s ease;
    }

    .inactive-flexgenie {
        width: 15px;
        height: 15px;
        border-radius: 15px;
        background-color: white;
        border: 1px solid #C23FA1;
        object-fit: contain;
        margin-right: 10px;
        transition: all 0.5s ease;
    }
}