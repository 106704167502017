.news {
    margin: 0 auto;
    margin-top: 65px;
    margin-left: 6%;
    margin-right: 6%;
    box-sizing: border-box;
    padding: 1% 0%;
    background-image: url("../assets/images/backgrounds/bg4.png");
    background-size: contain;
}

.news-container {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    /* background-color: yellow; */
    box-sizing: border-box;
}

.news-header {
    width: 100%;
    margin-bottom: 20px;
}

.news-body {
    margin-top: 30px;
    margin-bottom: 10px;
    padding: 0% 4%;
    /* background-color: pink; */
    width: 100%;
    box-sizing: border-box;
}

.news-footer {
    width: 100%;
    /* background-color: red; */
    padding: 5px 1%;
    box-sizing: border-box;
}

.news-header-container {
    position: relative;
    text-align: center;
    font: normal normal 600 43px Poppins;
    letter-spacing: 0px;
    color: #2B2B2B;
    opacity: 1;
}

.brush {
    position: absolute;
    top: -4px;
    right: 20%;
    box-sizing: border-box;
    width: 90%;
    max-width: 95px;
    min-width: 60px;
}

.news-body-container {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-around;
    align-items: center;
    row-gap: 20px;
    column-gap: 4%;
}

.news-footer-container {
    display: flex;
    flex-direction: row;
    justify-content: right;
    align-items: center;
    flex-wrap: wrap;
    box-sizing: border-box;
}

.news-paper-brand {
    display: flex;
    justify-content: center;
    align-items: center;
    box-sizing: border-box;
    /* background-color: red; */
    padding: 2px;
}

/* responsiveness */

@media only screen and (max-width: 768px) { 
    .brush {
        display: none;
    }
}