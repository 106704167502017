.faq {
  margin: 0 auto;
  box-sizing: border-box;
  margin-top: 50px;
  margin-left: 6%;
  margin-right: 6%;
  padding: 1% 0%;
}

.faq-container {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: wrap;
  box-sizing: border-box;
  row-gap: 30px;
  /* background-color: yellow; */
  padding: 0% 5%;
}

.faq-header {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  /* background-color: aqua; */
  box-sizing: border-box;
}

.faq-header-container {
  text-align: center;
  font: normal normal 600 42px Poppins;
  letter-spacing: 0px;
  color: #2b2b2b;
  opacity: 1;
  box-sizing: border-box;
}

.faq-body {
  flex: 1;
  box-sizing: border-box;
}

.faq-body-container {
  display: flex;
  flex-direction: column;
  /* background-color: blueviolet; */
  padding: 2% 0%;
  justify-content: flex-start;
  align-items: center;
}

.crab-img {
  position: absolute;
  top: 0px;
  left: 0px;
  width: 60%;
  max-width: 110px;
  min-width: 40px;
  box-sizing: border-box;
}

.accordion {
  margin-bottom: 10px;
  padding: 5px;
  border-radius: 15px;
  overflow: hidden;
}

.accordion-header {
  text-align: left;
  font: normal normal medium 20px Poppins;
  letter-spacing: 0px;
  color: #2b2b2b;
  opacity: 1;
  /* background-color: red; */
}

.accordion-body {
  text-align: left;
  font-family: Poppins;
  font-size: 25px;
  letter-spacing: 0px;
  color: #2b2b2b;
  opacity: 1;
}

/* responsiveness */

@media only screen and (max-width: 768px) {
  .crab-img {
    top: -70px;
    left: -30px;
  }

  .faq-header-container{
  font: normal normal 600 28px/42px Poppins;
  }
  .faq{
    margin: 50px 0px 0px 0px;
  }
  .accordion-header{
  /* font: normal normal medium 15px Poppins !important; */
  font-size: 15px !important;

  }
  .accordion-body{
    font-size: 14px;
  }
}

.MuiAccordionSummary-content {
  font-size: 30px !important;
}

.MuiAccordion-rounded {
  background: #FFFFFF no-repeat padding-box !important;
  border-radius: 15px !important;
  opacity: 1 !important;
  box-shadow: 0px 2px 8px 1px #eaf1fc !important;
  margin-bottom: 15px !important;
}
.MuiSvgIcon-root {
  fill: #185dd2 !important;
}
