.home-screen {
  width: 100%;
  padding-top: 60px;
  padding-bottom: 60px;
  /* background-color: yellow; */
  margin-top: 30px;

  background-image: url('../assets/images/backgrounds/bg0.png');
  background-size: contain;
}

.home-screen-flexgenie {
  width: 100%;
  margin-top: -44px;
  /* background-color: yellow; */
  background-color: white;
  background-size: contain;
  margin-bottom: -100px;
}

.home-screen-container {
  display: flex;
  flex-direction: row-reverse;
  /* flex-direction: row; */
  justify-content: center;
  align-items: center;
  /* margin-left: 5%;
    margin-right: 5%; this */
  margin-left: 4%;
  margin-right: 4%;
}

.home-screen-left {
  flex: 3;
  /* background-color: rgb(250, 250, 157); */
}

.home-screen-right {
  flex: 2;
}

.home-screen-right-container {
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  justify-content: center;
  align-items: right;
  /* background-color: olivedrab; */
}

.home-screen-subject {
  /* font: normal normal 600 42px/63px Poppins; */
  font: normal normal 600 38px/63px Poppins;
  letter-spacing: 0px;
  color: #2b2b2b;
  opacity: 1;
  margin-bottom: 30px;
}

.home-screen-subject-flexgenie {
  font: normal normal 600 42px Poppins;
  letter-spacing: 0px;
  color: #2b2b2b;
  opacity: 1;
  margin-bottom: 30px;
}

.home-screen-text {
  text-align: left;
  font: normal normal normal 20px/30px Poppins;
  letter-spacing: 0px;
  color: #2b2b2b;
  opacity: 1;

  margin-bottom: 20px;
}

.download-row-container {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: flex-start;
  column-gap: 20%;
  align-items: center;
}

.play-store-badge {
  background-image: url('../assets/images/play-store-badge.png');
  width: 157px;
  height: 52px;
  box-shadow: none;
  background-repeat: no-repeat;
  background-color: transparent;
  cursor: pointer;
  border: none;
}

.home-screen-images-row {
  display: flex;
  flex-direction: row;
  align-items: stretch;
  justify-content: center;
  /* background-color: rgb(195, 131, 255); */
}

.img-col {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  background-color: rgb(252, 112, 112);
}

.idea-img {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.reactor-img {
  position: absolute;
  top: -50px;
  left: -60px;
  /* background-color: red; */
}

.clock-img {
  position: absolute;
  bottom: -100px;
  left: -30px;
  /* background-color: black; */
}

.bullseye {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: stretch;
  /* background-color: #2B2B2B; */
  box-sizing: border-box;
  width: 90px;
  padding-bottom: 110px;
}

.bullseye-resp {
  display: none;
}

/* responsiveness */

@media only screen and (max-width: 768px) {
  .home-screen {
    padding-bottom: 0px;
  }

  .home-screen-flexgenie {
    padding-top: 60px;
    margin-top: 30px;
    margin-bottom: 0px;
  }

  .home-screen-container {
    flex-direction: column-reverse;
  }

  .clock-img {
    display: none;
  }

  .home-screen-images-row {
    width: 100%;
  }

  .octopus {
    display: none;
  }

  .bullseye {
    display: none;
  }

  .bullseye-resp {
    display: none;
  }

  .home-screen-subject {
    text-align: center;
    font: normal normal 600 28px Poppins;
  }

  .home-screen-subject-flexgenie {
    text-align: center;
    font: normal normal 600 28px Poppins;
  }

  .home-screen-text {
    text-align: center;
  }

  .download-row-container {
    justify-content: center;
  }

  .idea-img {
    /* flex-direction: column;
    align-items: center;
    justify-content: space-between; */
    display: none;
  }
}

a {
  -webkit-appearance: button;
  -moz-appearance: button;
  appearance: button;

  text-decoration: none;
  color: initial;
}