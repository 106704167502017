.testimonials {
  margin: 0 auto;
  box-sizing: border-box;
  /* margin-top: 50px; */
  margin-bottom: 50px;
  /* margin-left: 3%;
    margin-right: 3%; this */
  /* margin-left: 9%;
    margin-right: 9%; */
  /* padding: 1% 0%; */
  /* height: 700px; */
}

.testimonials-container {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: stretch;
  box-sizing: border-box;
  background-color: #185dd2;
  /* row-gap: 40px; */
  /* border-radius: 25px; */
}

.testimonials-header {
  position: relative;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;

  padding: 3%;
  /* background-color: aqua; */

  text-align: left;
  font: normal normal 600 42px Poppins;
  letter-spacing: 0px;
  color: #ffffff;
  opacity: 1;
}

.testimonials-body {
  box-sizing: border-box;
}

.testimonials-header-container {
  box-sizing: border-box;
}

.testimonials-body-container {
  box-sizing: border-box;
  padding: 10px 0% 30px 0%;
  /* display: none; */
}

.testimonials-body-container-normal {
  box-sizing: border-box;
  /* padding: 10px 0% 60px 0%; */
  overflow: hidden;
  /* padding-bottom: 40px; */
  margin-top: 10px;
}

.testimonials-body-container-normal-1 {
  display: none
}

.athena-img {
  position: absolute;
  top: -172px;
  left: 20px;
  width: 90%;
  min-width: 100px;
  max-width: 210px;
}

.bees-and-bear-img {
  position: absolute;
  top: -120px;
  right: -20px;
  width: 90%;
  min-width: 140px;
  max-width: 280px;
}

.testimonial-cards {
  /* height: 280px; this */
  /* height: 252px; */
  overflow-y: hidden;
  overflow-x: auto;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  /* align-items: center; this */
  align-items: flex-start;
  column-gap: 60px;
  row-gap: 40px;
}

/* ::-webkit-scrollbar {
    display: none;
  } */
div::-webkit-scrollbar {
  display: none;
}

#testimonial-animation {
  display: none;
}

.react-multi-carousel-dot--active button {
  background: #ffffff !important;
}

.react-multi-carousel-dot button {
  border-color: #ffffff !important;
}

.testimonials-img {
  max-width: 100%;
}

@media only screen and (max-width: 768px) {
  .testimonials-body-container {
    position: relative;
  }

  .testimonials-img {
    height: 100px;
    width: 100px;
  }

  .carousel {
    position: static;
  }

  .testimonials-header {
    font: normal normal 600 28px Poppins;
  }

  .testimonials {
    height: unset;
    margin-top: 0% !important;
  }

  .testimonials-body-container-normal {
    display: none;
  }

  #testimonial-animation {
    display: unset;
  }

  .testimonials-container {
    row-gap: 0px;
    background-color: #185DD2;
    /* margin: 10px; */
    /* border-radius: 30px; */
  }

  div#myCarousel.carousel.slide {
    padding-bottom: 60px !important;
    margin-bottom: 20px !important;
  }

  .carousel-inner {
    overflow: visible;
  }

  .carousel-indicators {
    padding: 0% !important;
    bottom: 18px;
  }

}

.react-multi-carousel-dot .react-multi-carousel-dot--active {
  background-color: #185dd2 !important;
}

#box {
  animation-name: move;
  animation-duration: 20s;
  animation-iteration-count: infinite;
  animation-direction: right;
  animation-timing-function: linear;
  -moz-animation-name: move;
  -o-animation-name: move;
  animation-name: move;
}

#box:hover {
  animation-play-state: paused;
}

.react-multi-carousel-track {
  height: 330px;
}

.react-multi-carousel-item {
  width: 650px !important;
}

.carousel-indicators {
  left: 70% !important;
}

@-webkit-keyframes move {
  100% {
    margin-left: -2200px;
  }

  0% {
    margin-left: 800px;
  }
}

@-moz-keyframes move {
  100% {
    margin-left: -2200px;
  }

  0% {
    margin-left: 800px;
  }
}

@keyframes move {
  100% {
    margin-left: -2200px;
  }

  0% {
    margin-left: 800px;
  }
}