.download-wrapper{
    background: #185DD2 0% 0% no-repeat padding-box;
    position: relative;
    padding: 70px 0px;
    overflow: hidden;
}
.download {
    width: 80%;
    margin: auto;
    box-sizing: border-box;
    /* background-color: yellow; */
    background: #185DD2 0% 0% no-repeat padding-box;
    display: flex;
    justify-content: center;
}

.download-container {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: stretch;
    box-sizing: border-box;
    column-gap: 1%;
    /* background-color: aqua; */
    flex-wrap: nowrap;
}

.download-left {
    left: 1;
    display: flex;
    flex-direction: row;
    box-sizing: border-box;
    align-items: flex-start;
}

.download-right {
    display: flex;
    flex-direction: row;
    box-sizing: border-box;
    align-items: flex-end;
}

.download-left-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    box-sizing: border-box;
    /* background-color: greenyellow; */
    row-gap: 18px;
}

.download-right-container {
    position: absolute;
    bottom: -8px;
    right: 20px;
}

.download-btn-list {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    /* background-color: blue; */
    column-gap: 2%;
    row-gap: 20px;
    width: 100%;
    box-sizing: border-box;
}

.download-text {
    color: white;
    text-align: center;
    width: 70%;
    font: 26px Poppins;
    letter-spacing: 0px;
    opacity: 1;
}

a {
    -webkit-appearance: button;
    -moz-appearance: button;
    appearance: button;

    text-decoration: none;
    color: initial;
}

/* responsiveness */

@media only screen and (max-width: 768px) { 
    .download {
        margin-left: 0px;
        margin-right: 0px;
        border-radius: 0px;
        background-color: #185DD2;
        width: 100%;
    }
    
    
    .download-container {
        flex-direction: column;
        row-gap: 20px;
    }
    
    .download-right {
        justify-content: flex-end;
    }
    .download-right-container{
        position: static;
        margin-bottom: -48px;
    }
    .download-wrapper{
    padding: 40px 0px;
    }
    .download-text{
        font: 28px Poppins;
        width: 100%;
        padding: 0px;
        color: white;
    }
}