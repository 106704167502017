.smfy {
    margin: 0 auto;
    margin-top: 50px;
    margin-bottom: 50px;
    /* margin-left: 10%;
    margin-right: 10%; this */
    margin-left: 11%;
    margin-right: 1%;
    box-sizing: border-box;
    padding: 1% 0%;
    background-image: url("../assets/images/backgrounds/bg3.png");
    background-size: contain;
}

.smfy-flexgenie {
    margin: 20px auto;
    /* margin-left: 10%;
    margin-right: 10%; this */
    margin-left: 11%;
    margin-right: 1%;
    box-sizing: border-box;
    padding: 1% 0%;
    background-color: white;
}

.smfy-container {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    flex-wrap: wrap;
    /* background-color: aqua; */
    box-sizing: border-box;
    column-gap: 5%;
}

.smfy-left {
    flex: 1;
    box-sizing: border-box;
}

.smfy-left-flexgenie {
    flex: 7;
    box-sizing: border-box;
}

.smfy-right-flexgenie {
    flex: 5;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: auto;
}

.smfy-right {
    flex: 1;
    box-sizing: border-box;
}

.smfy-left-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    /* background-color: yellow; */
    box-sizing: border-box;
}

.smfy-right-container {
    width: 100%;
    height: 100%;
    /* background-color: yellowgreen; */
    display: flex;
    justify-content: center;
    justify-content: center;
    box-sizing: border-box;
}

.smfy-title {
    text-align: left;
    font: normal normal 600 42px Poppins;
    letter-spacing: 0px;
    color: #2B2B2B;
    opacity: 1;
    margin-bottom: 8px;
}

.smfy-sub-title {
    text-align: left;
    font: normal normal 24px Poppins;
    letter-spacing: 0px;
    color: #2B2B2B;
    opacity: 1;
    margin-bottom: 28px;
}

.smfy-list {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    /* background-color: olivedrab; */
    flex-wrap: wrap;
    row-gap: 14px;
    margin-bottom: 35px;
    box-sizing: border-box;
}

.smfy-list-item {
    text-align: left;
    font: normal normal 20px Poppins;
    letter-spacing: 0px;
    color: #2B2B2B;
    opacity: 1;
}

.osm-explore-btns {
    cursor: pointer;
    background: #185DD2 0% 0% no-repeat padding-box;
    border-radius: 90px;
    border: none;
    padding: 30.5% 100.5%;
    text-align: center;
    font: normal normal 600 14px Poppins;
    letter-spacing: 0px;
    color: #ffffff;
    text-transform: uppercase;
    opacity: 1;
}

.desktpsOnly {
    width: 100%;
    /* text-align: center; */

}

.smfy-list-body {
    display: flex;
    list-style: none;

}

a {
    -webkit-appearance: button;
    -moz-appearance: button;
    appearance: button;

    text-decoration: none;
    color: initial;
    text-decoration: none;
}

#bulletian {
    height: 8px;
    min-width: 8px;
    border-radius: 50%;
    background-color: black;
    margin-right: 20px;
    margin-top: 10px;
}

@media only screen and (max-width: 768px) {
    .smfy {
        margin-left: 0%;
        margin-right: 0%;
        padding: 0% 5%;
    }

    .smfy-flexgenie {
        margin: 20px;
    }

    .smfy-list {
        list-style: none;
    }

    .desktopsOnly {
        width: 100%;
        text-align: center;

    }

    .smfy-container {
        flex-direction: column-reverse;
    }

    #bulletian {
        min-height: 8px;
        min-width: 8px;
        border-radius: 50%;
        background-color: black;
        margin-right: 12px;
        margin-top: 8px;
    }

    .smfy-list-body {
        display: flex;
    }

    .smfy-right {
        margin-top: 20px;
        margin-bottom: 20px;
    }

    .smfy-right-flexgenie {
        margin-top: 20px;
        margin-bottom: 20px;
    }

    .smfy-title {
        text-align: left;
        font: normal normal 600 28px Poppins;
        letter-spacing: 0px;
        color: #2B2B2B;
        opacity: 1;
        margin-bottom: 20px;
    }

    .smfy-sub-title {
        text-align: left;
        font: normal normal 500 20px Poppins;
        letter-spacing: 0px;
        color: #2B2B2B;
        opacity: 1;
        margin-bottom: 20px;
    }

    .smfy-list-item {
        text-align: left;
        font: normal normal normal 16px Poppins;
        letter-spacing: 0px;
        color: #2B2B2B;
        opacity: 1;
    }

    .smfy-left-container {
        background-color: white;
    }

    .osm-explore-btns {
        cursor: pointer;
        background: #185DD2 0% 0% no-repeat padding-box;
        border-radius: 90px;
        border: none;
        padding: 12px;
        width: 70%;
        height: 50px;
        display: flex;
        align-items: center;
        justify-content: center;
        margin: auto;
        text-decoration: none !important;
        text-align: center;
        font: normal normal 600 18px Poppins;
        letter-spacing: 0px;
        color: #FFFFFF;
        text-transform: uppercase;
        opacity: 1;
    }
}