/* .wcf {
    margin-top: 10px;
    box-sizing: border-box;
    padding: 3%;
    margin-left: 6%;
    margin-right: 6%;
} */

.wcf-container {
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: flex-start;
  /* background-color: rgb(109, 95, 173); */
  background-color: #185dd2;
  /* border-radius: 15px; */
  box-sizing: border-box;
}

.wcf-container-flexgenie {
  background-color: #C23FA1;
}

.octopus {
  position: absolute;
  top: -70px;
  right: -60px;
  box-sizing: border-box;
  width: 90%;
  max-width: 270px;
  max-height: 270px;
  min-width: 150px;
  min-height: 150px;
}

.mask {
  position: absolute;
  top: -40px;
  left: -10px;
  box-sizing: border-box;
  width: 90%;
  max-width: 240px;
  max-height: 240px;
  min-width: 130px;
  min-height: 130px;
  z-index: 1;
}

.wcf-container-left {
  flex: 4;
  /* padding-top: 50px; */
}

.wcf-container-right {
  flex: 6;
  /* background-color: red; */
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  padding: 30px;
  border-radius: 15px;
}

.wcf-container-left-container {
  position: relative;
  width: 100%;
  height: 100%;
  padding-left: 28%;
  padding-right: 0%;
  box-sizing: border-box;
  overflow: hidden;
  padding-top: 50px;
  border-top-left-radius: 15px;
  /* background-color: yellow; */
  /* background-color: black; */
}

.text-1 {
  position: relative;
  z-index: 2;
  font: normal normal 600 30px Poppins;
  letter-spacing: 0px;
  color: #ffffff;
  opacity: 1;
  /* z-index: 1; */
}

.text-2 {
  position: relative;
  z-index: 2;
  font: normal normal normal 17px Poppins;
  /* font: normal normal normal 20px/30px Poppins; */
  letter-spacing: 0px;
  color: #ffffff;
  opacity: 1;
}

.onlyMobile {
  display: none;
}

.explore-btn {
  cursor: pointer;
  background: #ffffff 0% 0% no-repeat padding-box;
  border-radius: 90px;
  border-width: 0px;
  opacity: 1;
  border-color: unset;
  padding: 3.5% 6%;

  font: normal normal 600 13px Poppins;
  letter-spacing: 0px;
  color: #185dd2;
  text-transform: uppercase;
  opacity: 1;
  margin-top: 30px;
}

/* responsiveness */

@media only screen and (max-width: 768px) {
  .wcf {
    padding: 3% 0%;
  }

  /* .explore-btn{
  display: none;
} */
  .wcf-container {
    flex-direction: column;
  }

  .onlyMobile {
    display: block;
    width: 100%;
    text-align: center;

  }

  .desktopOnly {
    display: none;
  }

  .wcf-container-left-container {
    padding-left: 5%;
    padding-right: 5%;
    text-align: center;
  }

  .wcf-container-right {
    padding-left: 5%;
    padding-right: 5%;
  }

  .mask {
    width: 40%;
    min-width: none;
    min-height: none;
  }

  .octopus {
    width: 50%;
    min-width: none;
    min-height: none;
    top: -5%;
    right: -9%;
  }

  .explore-btn {
    width: max-content;
    align-items: center;
  }

  .explore-btn {
    padding: 4.5% 9%;
    font-size: 16px;
  }

  .text-1 {
    font: normal normal 600 28px Poppins;
  }

}

@media only screen and (min-width: 1610px) {
  .wcf-container-left {
    flex: 7 1;
  }
}