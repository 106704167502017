.wcf-card {
    padding: 10px;
    margin: 20px;
    /* background-color: aqua; */
    width: 20%;

    min-width: 180px;
    max-width: 270px;
    width: 100%;
}

.card-container {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    /* background-color: blueviolet; */
}

.card-img {
    max-width: 100%;
    max-height: 100%;
    margin-bottom: 10px;
}

.card-img-container {
    padding: 20%;
    background: #FFFFFF 0% 0% no-repeat padding-box;
    border-radius: 14px;
    opacity: 1;
    width: 50px;
    height: 50px;
    box-sizing: border-box;
}

.card-col {
    flex: 1;
}

.card-header {
    font: normal normal 600 18px/27px Poppins;
    letter-spacing: 0px;
    color: #FFFFFF;
    opacity: 1;
    margin-bottom: 5px;
}

.card-description {
    text-align: left;
    font: normal normal normal 16px Poppins;
    letter-spacing: 0px;
    color: #EAEAEA;
    opacity: 1;
}

/* responsiveness */

@media only screen and (max-width: 768px) {
    .wcf-card {
        /* margin-left: 0px;
        margin-right: 0px; */
        max-width: none;
        margin: 0px;
    }

    .card-img-container {
        width: 65px;
        height: 65px;
        margin-bottom: 6px;
    }

    .card-container {
        margin-bottom: 12px;
    }

}